import React, {useEffect, useState} from 'react';
import SEO from '../components/App/SEO';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import {Link} from 'gatsby';
import { graphql } from 'gatsby';



const SecurityNew = ({data}) => {

    const link = useState();

    useEffect(() => {    
        const scrollPosition = sessionStorage.getItem('scrollPosition');
        if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition, 10));
            sessionStorage.removeItem('scrollPosition');
        }
    }, link);

    return (
        <Layout env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO
            postTitle="Security | BlueMail App"
            postDescription="Security and privacy at BlueMail is a top priority for our team. We use the leading industry protocols and standards to keep you and your data safe."
            postImage="/img/OG/og_image-security.png"
            postURL="security"
            postSEO
        />
        <div className="container-fluid security-header">
            <div className="container security-header-content">
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-xs-12 mb-5'>
                        <div className='row'>                            
                            <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                                <h1>BlueMail <br />
                                    Security Center</h1>
                                <p>At BlueMail, the security and privacy of our users is a top priority and we 
                                    are fully transparent about our practices. We strongly believe that only  you 
                                    should control your data and we never store emails or sell any of your data.</p>
                            </div>
                            <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                                <img src='/img/security-new/secure.svg' alt='Lock' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container security-new">
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-12 mb-5'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-xs-12 d-block m-auto text-center'>
                            <img className='security-lock' src='/img/security-new/security-lock.svg' alt='security-lock' />
                        </div>
                        <div className='col-lg-7 col-md-7 col-xs-12 d-block m-auto'>
                            <h3>Our commitment to Security, Privacy, Compliance & Transparency</h3>
                            <p>We invest a lot of effort in developing the best email solutions for individuals 
                                and businesses, with a special emphasis on privacy, reliability, efficiency, and 
                                security. Our state-of-the-art technological architecture allows us to deliver 
                                safer top-tier email and calendar services for you and your organization, while still 
                                providing a great user experience throughout.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-11 col-md-11 col-xs-12 d-block ml-auto mr-auto mt-5 mb-3 text-center when-chose'>
                    <h6>When you choose BlueMail, you not only choose an industry leading email communication software,
                        but you choose a company who genuinely cares about your privacy and the protection of your data.</h6>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-12 d-block mt-5 mb-5 learn'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-xs-12 d-block ml-auto mr-auto text-center'>
                            <h5>Learn more below about our security and data protection measures</h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-xs-12 d-block ml-auto mr-auto mb-5 text-center'>
                            <div className='row'>                                
                                <div className='col-lg-6 col-md-6 col-xs-12 mt-5'>
                                    <Link to='/security/oauth/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                        <img src='/img/security-new/OAuth2Block.svg' alt='http' />
                                        <h6 className='mt-5' style={{textAlign: 'left', fontWeight: '600'}}>OAuth2</h6>
                                    </Link>
                                        <h6 className='mt-1 mb-2' style={{fontWeight: '300', textAlign: 'left', lineHeight: '24px', fontSize: '20px', fontWeight: '400'}}>A Secure Authorization Protocol for All Major Providers</h6>
                                        <p style={{textAlign: 'left'}}>When adding your account, BlueMail uses highly private and secure authentication protocols and does not have access 
                                            to your account password at <b>any time.</b>
                                        </p>
                                        <p className='br-hide'>&nbsp;</p>
                                    <Link to='/security/oauth/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                        <button className='learn-btn'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                                    </Link>
                                </div>                                
                                <div className='col-lg-6 col-md-6 col-xs-12 mt-5'>                                    
                                    <Link to='/features-functions/instant-push/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                        <img src='/img/security-new/connect-directly.svg' alt='Connect directly' />
                                        <h6 className='mt-5' style={{textAlign: 'left', fontWeight: '600'}}>Connect Directly to Your Email Provider</h6>
                                    </Link>
                                        <h6 className='mt-1 mb-2' style={{fontWeight: '300', textAlign: 'left', lineHeight: '24px', fontSize: '20px', fontWeight: '400'}}>Emails are not stored on our servers</h6>
                                        <p style={{textAlign: 'left'}}>Emails are directly synced between the BlueMail 
                                        app and your email provider. For mobile devices only, you can opt in to receive push notifications via our secure push proxy. 
                                        At any rate, your emails are never stored on BlueMail’s servers.
                                        </p>
                                    <Link to='/features-functions/instant-push/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                        <button className='learn-btn'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                                    </Link>
                                </div>
                            </div>                            
                            <br className='br-hide'/>
                            <br className='br-hide'/>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-xs-12 mt-5'>
                                    <Link to='/security/ssl-tls-starttls/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                        <img src='/img/security-new/ssl-tls.svg' alt='SSL/TLS' />
                                        <h6 className='mt-5' style={{textAlign: 'left', fontWeight: '600'}}>SSL/TLS and STARTTLS</h6>
                                    </Link> 
                                        <h6 className='mt-1 mb-2' style={{fontWeight: '300', textAlign: 'left', lineHeight: '24px', fontSize: '20px', fontWeight: '400'}}>Securing your communication channel</h6>
                                        <p style={{textAlign: 'left'}}>BlueMail utilizes industry leading security protocols to exchange emails 
                                            on a 2-way encrypted channel.
                                        </p> 
                                        <p className='br-hide'>&nbsp;</p>
                                    <Link to='/security/ssl-tls-starttls/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                        <button className='learn-btn'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                                    </Link>
                                </div>
                                <div className='col-lg-6 col-md-6 col-xs-12 mt-5'>
                                    <Link to='/blog/pixel-blocking/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                        <img src='/img/security-new/tracking-prevention.svg' alt='Tracking'/>
                                        <h6 className='mt-5' style={{textAlign: 'left', fontWeight: '600'}}>Tracking Prevention</h6>
                                    </Link>   
                                        <h6 className='mt-1 mb-2' style={{fontWeight: '300', textAlign: 'left', lineHeight: '24px', fontSize: '20px', fontWeight: '400'}}>Block All Tracking images</h6>
                                        <p style={{textAlign: 'left'}}>BlueMail by default attempts to block all intrusive email trackers, 
                                        including tracking pixels to prevent your personal data from being exposed to email marketing services and spammers.
                                        </p>
                                    <Link to='/blog/pixel-blocking/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                        <button className='learn-btn'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-xs-12 smime'>                            
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-xs-12 d-block ml-auto mr-auto mt-5 mb-5 text-center'>
                                <Link to='/security/smime/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                    <h6 className='mt-3'><b>S/MIME</b></h6>
                                </Link>    
                                    <h6 style={{fontWeight: '400'}}>Combine digital signatures and email encryption to create an end-to-end secure layer</h6>
                                    <p>Use S/MIME on BlueMail to digitally sign, encrypt and verify emails, preventing unauthorized access to the content of 
                                        your emails.</p>  
                                <Link to='/security/smime/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                    <button className='learn-btn ml-auto mr-auto'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                                </Link>
                                </div>
                                
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-xs-12 text-center d-block m-auto'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-xs-6'>
                                            <p style={{fontSize: '14px'}}>Sender</p>
                                            <img className='space' src='/img/security-new/desktop.svg' alt='Sender'/>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-xs-6 d-block m-auto'>
                                            <p style={{fontSize: '14px'}}>Plain Text</p>
                                            <img className='security-arrow' src='/img/security-new/Arrow9.svg' alt='Plain Text'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-xs-12 text-center d-block m-auto'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-4 col-xs-4 d-block m-auto'>
                                            <p style={{fontSize: '14px', lineHeight: '14px', margin: '-20px auto 10px'}}>Recipient`s Public Key</p>
                                            <img src='/img/security-new/public-key.svg' alt='Public Key'/>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-xs-4 d-block m-auto'>
                                            <p style={{fontSize: '13px'}}>Encrypted Email</p>
                                            <img className='security-arrow' src='/img/security-new/Arrow9.svg' alt='Encrypted'/>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-xs-4 d-block m-auto'>
                                            <p style={{fontSize: '14px', lineHeight: '14px', margin: '-20px auto 10px'}}>Recipient`s Private Key</p>
                                            <img src='/img/security-new/private-key.svg' alt='Private Key'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-xs-12 text-center d-block m-auto'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-xs-6 d-block m-auto'>
                                            <p style={{fontSize: '14px'}}>Plain Text</p>
                                            <img className='security-arrow' src='/img/security-new/Arrow9.svg' alt='Plain Text'/>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-xs-6'>
                                            <p style={{fontSize: '14px'}}>Recipient</p>
                                            <img src='/img/security-new/recipient.svg' alt='Recipient'/>
                                        </div>                        
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>                    
                </div>
            </div>  
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-12 mt-5 mb-3'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                            <img src='/img/security-new/data-collection.svg' alt='Data'/>
                        </div>
                        <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                            <h3>Minimal data collection</h3>
                            <p>BlueMail always aspires to process the <b>least amount of data</b> possible from you, while creating 
                                a powerful, sleek and intuitive experience. We strongly believe privacy and security are a top 
                                priority. BlueMail is 100% safe - emails are <b>not stored</b> on our cloud and users' data 
                                is <b>never sold.</b> </p>
                        </div>                        
                    </div>
                </div>
            </div>  
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-12 mt-2 mb-3'>
                    <Link to='/gdpr/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                                <h3 className='mt-5'>GDPR & CCPA compliant</h3>                            
                                <p>BlueMail complies with t data protection regulations 
                                including the <Link style={{fontWeight: '400', color: 'rgba(0, 0, 0, 0.75)'}} to="/gdpr/">GDPR</Link> & CCPA.</p>
                                <button className='learn-btn'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                            </div>
                            <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                                <img src='/img/security-new/gdpr-compliant.svg' alt='Compliance'/>
                            </div>
                        </div>                         
                    </Link>
                </div>
            </div>    
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-12 mt-2 mb-3'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-xs-12 mb-5 text-center'>        
                            <h5>More Security Features</h5>   
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-6 col-md-6 col-xs-12 text-center'>   
                            <Link to='/security/block-list/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>  
                                <img src='/img/security-new/block-list.svg' alt='Compliance'/>
                                <h6 style={{fontWeight: '600'}}>The Block List</h6> 
                            </Link>
                                <h6 style={{lineHeight: '24px', fontSize: '20px', fontWeight: '400'}}>BlueMail’s Spam Management Tool <br className='brSc'/> &nbsp;</h6>
                            <Link to='/security/block-list/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                <button className='learn-btn ml-auto mr-auto'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                            </Link>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 col-xs-12 text-center'>                                                                           
                            <Link to='/security/lock-screen/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>   
                                <img src='/img/security-new/lock-screen.svg' alt='Compliance'/>
                                <h6 style={{fontWeight: '600'}}>Lock Screen</h6>   
                            </Link>  
                                <h6 style={{lineHeight: '24px', fontSize: '20px', fontWeight: '400'}}>Safeguard your device with a passcode or fingerprint security</h6>
                            <Link to='/security/lock-screen/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                <button className='learn-btn ml-auto mr-auto'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                            </Link>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 col-xs-12 text-center md'>
                            <Link to='/blog/magicsync-settings-sync/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>   
                                <img src='/img/security-new/magic-sync.svg' alt='Compliance'/>
                                <h6 style={{fontWeight: '600'}}>MagicSync</h6>                               
                            </Link>
                                <h6 style={{lineHeight: '24px', fontSize: '20px', fontWeight: '400'}}>A Secure Backup and Sync of Your Account Data <br className='brSc sec'/> &nbsp;</h6>
                            <Link to='/blog/magicsync-settings-sync/' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                <button className='learn-btn ml-auto mr-auto'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                            </Link>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 col-xs-12 text-center md'> 
                            <Link to='/features-functions/private-notifications' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>   
                                <img src='/img/security-new/private-notifications.svg' alt='Compliance'/>
                                <h6 style={{fontWeight: '600'}}>Private Notifications</h6>                               
                            </Link>
                                <h6 style={{lineHeight: '24px', fontSize: '20px', fontWeight: '400'}}>Secure and Customizable Notifications <br className='brSc sec'/> &nbsp;</h6>
                            <Link to='/features-functions/private-notifications' onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)}>
                                <button className='learn-btn ml-auto mr-auto'>Learn More <img style={{marginLeft: '8px'}} src='/img/security-new/learn-arrow.svg' alt='http' /></button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
        <div className='container-fluid pt-120 pb-120 contact-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7 col-md-7 col-xs-12 d-block m-auto text-center'>
                    <h3 style={{lineHeight: '36px'}}>Have a security related question or issue?</h3>
                    <p className='mt-3' style={{fontSize: '16px', lineHeight: '22px', margin: '0 45px'}}>If you believe you have found a security vulnerability on BlueMail, 
                    we encourage you to <Link style={{color: '#1F6BF1', fontWeight: '700'}} to="/contact-us/">get in touch</Link> with our security team right away.</p>
                    <p className='mt-3' style={{fontSize: '16px', lineHeight: '22px', margin: '0 45px'}}> Read more about our <Link style={{color: '#000', fontWeight: '700'}} to="/privacy/">Privacy Policy </Link> 
                    and <Link style={{color: '#000', fontWeight: '700'}} to="/tos/">Terms of Service</Link></p> 
                    </div>
                </div>      
            </div>
        </div>
        <Footer />
        </Layout>
    )
}

export default SecurityNew;

export const query = graphql`
query SecurityNewPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
